import React from "react";
import { useDispatch, useSelector } from "react-redux";
import useOrder from "hooks/useOrder";
import { toast } from "react-toastify";
import {
  ADD_CREDITS_TO_WALLET,
  SEND_USER_CLICK_ANALYTICS,
} from "redux-store/sagas/saga-actions";
import DefaultInput from "views/components/input/DefaultInput";
import css from "../index.module.css";
import SecondaryButton from "views/components/button/SmallRoundedButton";
import { useSearchParams } from "react-router-dom";
import ANALYTICS from "constants/analyticsKeys";

const QUICK_AMOUNTS = {
  INR: [100, 300, 500, 1000],
  USD: [10, 20, 50, 100],
  GBP: [10, 20, 50, 100],
};

const AddCredits = ({
  className = "",
  titleClass = "",
  creditsRequired = 0,
  overrideCurrency = null,
}) => {
  const dispatch = useDispatch();

  const [moneyToAdd, setMoneyToAdd] = React.useState(100);
  const labelRef = React.useRef(null);

  const { paymentsCurrency = "INR" } = useSelector((state) => state.globals);
  const currencyToUse = overrideCurrency || paymentsCurrency;
  const {
    credit,
    wallet: { conversionRate },
  } = useSelector((state) => state.settings);

  const [params] = useSearchParams();
  const frontendType = params.get("frontendType") || "web";
  const { handlePayment } = useOrder(frontendType, "wallet");

  React.useEffect(() => {
    if (credit?.id) {
      handlePayment();
    }
  }, [credit]);

  const currency = React.useMemo(() => {
    return currencyToUse === "INR"
      ? {
          symbol: "₹",
          min: 10,
          max: 10000,
          step: 5,
          minToDisplay: 100,
          rate: conversionRate?.oneRupeeToCredits,
          minToAdd: 10,
        }
      : currencyToUse === "GBP"
      ? {
          symbol: "£",
          min: 5,
          max: 300,
          step: 0.5,
          minToDisplay: 10,
          rate: conversionRate?.onePoundToCredits,
          minToAdd: 100,
        }
      : {
          symbol: "$",
          min: 5,
          max: 300,
          step: 0.5,
          minToDisplay: 10,
          rate: conversionRate?.oneDollarToCredits,
          minToAdd: 100,
        };
  }, [currencyToUse, conversionRate]);

  React.useEffect(() => {
    if (creditsRequired) {
      const nearestAmount =
        Math.ceil(creditsRequired / currency.rate / currency.step) *
        currency.step;

      if (nearestAmount < currency.min) setMoneyToAdd(currency.min);
      else if (nearestAmount > currency.max) setMoneyToAdd(currency.max);
      else setMoneyToAdd(nearestAmount);
    } else {
      if (currencyToUse !== "INR") setMoneyToAdd(10);
      else setMoneyToAdd(100);
    }
  }, [creditsRequired, currency, currencyToUse]);

  React.useLayoutEffect(() => {
    let str;

    switch (currencyToUse) {
      case "INR":
        str = `= ${conversionRate?.oneRupeeToCredits * moneyToAdd} credits`;
        break;
      case "USD":
        str = `= ${conversionRate?.oneDollarToCredits * moneyToAdd} credits`;
        break;
      case "GBP":
        str = `= ${conversionRate?.onePoundToCredits * moneyToAdd} credits`;
        break;
    }

    labelRef.current.parentElement.setAttribute("data-credits", str);
    labelRef.current.parentElement.setAttribute(
      "data-currency-symbol",
      currency.symbol
    );
  }, [moneyToAdd, currencyToUse, conversionRate, currency]);

  const handleMoneyAdd = () => {
    dispatch({
      type: SEND_USER_CLICK_ANALYTICS,
      payload: { buttons: [ANALYTICS.WALLET_ADD] },
    });

    const creditsToAdd = moneyToAdd * currency.rate;

    if (creditsToAdd < currency.minToAdd) {
      return toast.error(
        `Please add a minimum of ${currency.minToAdd} credits`
      );
    }
    if (moneyToAdd > currency.max) {
      return toast.error(
        `Please add a maximum of ${currency.symbol}${currency.max} at a time`
      );
    }
    if (moneyToAdd % currency.step !== 0) {
      return toast.error(
        `Please select any amount in multiples of ${currency.step}`
      );
    }

    dispatch({
      type: ADD_CREDITS_TO_WALLET,
      payload: {
        credits: creditsToAdd,
        currency: currencyToUse,
        frontendType,
      },
    });
  };

  return (
    <div className={className}>
      <h3 className={titleClass}>Add Credit</h3>
      <p className="color-h font-15 mb-2 d-flex align-items-center gap-1">
        <img
          src="/assets/images/icons/info-plain.png"
          alt="info plain"
          className="info-icon"
        />
        <span>
          Add credits in the multiples of {`${currency.symbol}${currency.step}`}
        </span>
      </p>
      <DefaultInput
        ref={labelRef}
        type="number"
        className="my-2 ps-5 font-17 font-base text-black"
        labelClassName={css["credit-input"]}
        placeholder="Amount"
        value={moneyToAdd}
        step={currency.step}
        min={currency.min}
        max={currency.max}
        onChange={(e) => setMoneyToAdd(e.target.value)}
      />
      <p className="mb-3 font-15 text-black text-start">
        Tax/GST will be added.
        <br />
        <span className="color-h">
          Add a minimum of&nbsp;
          {`${currency.symbol}${currency.minToDisplay}`} for a seamless
          experience
        </span>
      </p>
      <div className="d-flex justify-content-between gap-2 flex-wrap py-1">
        {QUICK_AMOUNTS[currencyToUse]?.map((amount, index) => (
          <button
            key={index}
            className={css["quick-add"]}
            onClick={() => setMoneyToAdd(amount)}
          >
            {currency.symbol}
            {amount}
          </button>
        ))}
      </div>

      <SecondaryButton
        title="Add"
        rounded={false}
        variant="md"
        className="font-bold font-16 mt-3 w-100"
        onClick={handleMoneyAdd}
      />
    </div>
  );
};

export default AddCredits;
